import {
  BellRinging,
  ChartBar,
  Cube,
  HouseLine,
  ShoppingCart,
  Users,
  User,
  SpeakerHigh,
  GearSix,
  Storefront,
  Gauge,
  TrendUp,
  Waves,
  MagicWand,
  Factory,
  PhosphorLogo,
  ShoppingCartSimple,
  AlignBottom,
} from "phosphor-react";
import { add_category } from "../Routes/RoutePaths";
import * as path from "../Routes/RoutePaths";

export const Navbar_items = {
  Dashboard: {
    main: "Dashboard",
    to: "/",
    icon: HouseLine,
  },
  Categories: {
    main: "Categories",
    to: "/product/categories",
    icon: Gauge,
  },

  Notification: {
    main: "Notification",
    to: path.notification,
    icon: BellRinging,
  },
  Reports: {
    main: "Reports",
    // to: path.reports,
    icon: ChartBar,
    sublinks: [
      {
        name: "Report",
        to: path.reports,
      },
      // {
      //   name: "Daily Reports",
      //   to: path.sales_report,
      // },
      {
        name: "Product Reports",
        to: path.sales_report_dash,
      },
      {
        name: "Sales Reports",
        to: path.sales_report_inv,
      },
      // {
      //   name: "Daily Reports",
      //   to: path.sales_report_optimise,
      // },
      {
        name: "Discount Report",
        to: path.discount_report,
      },
      {
        name: "Area Reports",
        to: path.area_report,
      },
    ],
  },
  Customers: {
    main: "Customers",
    // to: path.customer_list,
    icon: User,
    sublinks: [
      {
        name: "Customer List",
        to: path.customer_list,
      },
      {
        name: "Bulk Import",
        to: path.customer_bulk_import,
      },
    ],
  },
  Outlets: {
    main: "Outlets",
    // to: path.customer_list,
    icon: Storefront,
    sublinks: [
      {
        name: "Outlet List",
        to: path.outlet_list,
      },
      {
        name: "Outlet users",
        to: path.outlet_user_list,
      },
      {
        name: "Outlet Products",
        to: path.outlet_products_list,
      },
      {
        name: "Chalan List",
        to: "/outlet/chalan_list",
      },
      {
        name: "Return Products",
        to: path.return_products_list,
      },
      {
        name: "Internal Return",
        to: "/outlet/outlet_to_outlet_return_list",
      },
    ],
  },
  Promotions: {
    main: "Promotions",
    icon: SpeakerHigh,
    sublinks: [
      {
        name: "Review",
        to: path.review,
      },
      {
        name: "Offer",
        to: path.offer,
      },
      {
        name: "Combo Offer",
        to: path.combooffer,
      },
      // {
      //   name: "Coupon",
      //   to: path.cupons,
      // },
      {
        name: "Voucher",
        to: '/promotions/voucher-list',
      },
      {
        name: "Banner",
        to: path.banner,
      },

      {
        name: "Contact Us",
        to: path.contactUs,
      },
      {
        name: "Work With Us",
        to: path.work_with_us,
      },
    ],
  },
  Custom: {
    main: "Custom",
    // to: path.customer_list,
    icon: ShoppingCartSimple,
    sublinks: [
      {
        name: "Create Invoice",
        to: path.custom_create_invoice,
      },
      {
        name: "invoice List",
        to: path.custom_invoice_list,
      },
      // {
      //   name: "Sales Report",
      //   to: path.custom_sales_report,
      // },
    ],
  },
  Inventory: {
    main: "Inventory",
    icon: Cube,

    sublinks: [
      {
        name: "Add Category",
        to: path.add_category,
      },
      {
        name: "Category List",
        to: path.category_list,
      },
      {
        name: "Add Product",
        to: `/inventory/add-product/0`,
      },
      {
        name: "Home Decor",
        to: path.home_decor_list,
      },
      {
        name: "In Style",
        to: path.product_list,
      },
      {
        name: "Attribute",
        to: path.add_attribute,
      },
      {
        name: "Bulk Import",
        to: path.bulk_import,
      },
    ],
  },
  Combo: {
    main: "Combo",
    icon: Cube,

    sublinks: [
      {
        name: "Add Combo",
        to: "/inventory/add-combo-product",
      },
      {
        name: "Combo List",
        to: "/inventory/combo-product-list",
      },
    ],
  },
  New_Arrival: {
    main: "New_Arrival",
    icon: Waves,

    sublinks: [
      
      {
        name: "Add Multiple",
        to: path.add_multiple_arrival_product,
      },
      {
        name: "Arrival List",
        to: path.arrival_product_list,
      },
    ],
  },
  
  Trending: {
    main: "Trending",
    icon: TrendUp,

    sublinks: [
      // {
      //   name: "Add Trending",
      //   to: path.add_trending_product,
      // },
      {
        name: "Add Multiple",
        to: path.add_multiple_trending_product,
      },
      {
        name: "Trending List",
        to: path.trending_product_list,
      },
    ],
  },
  Festival: {
    main: "Festival",
    icon: MagicWand,
    sublinks: [
      {
        name: "Add Multiple",
        to: path.add_festival_product,
      },
      {
        name: "Product List",
        to: path.festival_product_list,
      },
      {
        name: "Festival List",
        to: path.festival_list,
      },
    ],
  },
  Highlighted: {
    main: "Highlighted",
    icon: Waves,

    sublinks: [
      
      {
        name: "Add Highlighted",
        to: path.add_multiple_highlighted_product,
      },
      {
        name: "Highlighted List",
        to: path.highlighted_product_list,
      },
    ],
  },
  Matching: {
    main: "Matching",
    icon: AlignBottom,
    sublinks: [
      {
        name: "Add Matching",
        to: path.add_matching,
      },
      {
        name: "Matching List",
        to: path.matching_list,
      },
    ],
  },
  Sales: {
    main: "Sales",
    icon: ShoppingCart,
    sublinks: [
      {
        name: "Create Invoice",
        to: path.create_invoice,
      },

      {
        name: "Invoice List",
        to: path.invoice_list,
      },
    ],
  },
  Draft: {
    main: "Draft",
    icon: ShoppingCart,
    sublinks: [
      {
        name: "Create Draft",
        to: path.create_draft_invoice,
      },
      {
        name: "Draft Invoice List",
        to: path.draft_invoice_list,
      },
    ],
  },
  Purchase: {
    main: "Purchase",
    icon: PhosphorLogo,

    sublinks: [
      {
        name: "Create Order",
        to: path.add_purchace_order,
      },

      {
        name: "Order List",
        to: path.purchace_order_list,
      },
      // {
      //   name: "Order Report",
      //   to: path.purchace_order_report,
      // },
    ],
  },
  Requisition: {
    main: "Requisition",
    icon: Factory,
    sublinks: [
      {
        name: "Create Order",
        to: path.add_requisition_order,
      },

      {
        name: "Order List",
        to: path.requisition_order_list,
      },
      // {
      //   name: "Order Report",
      //   to: path.requisition_order_report,
      // },
    ],
  },
  User: {
    main: "User",
    icon: Users,

    sublinks: [
      {
        name: "Add User",
        to: path.add_user,
      },
      {
        name: "Add Role",
        to: path.add_role,
      },
      {
        name: "Add Group",
        to: path.add_group,
      },
      {
        name: "Users List",
        to: path.user_list,
      },
      {
        name: "Website User",
        to: path.web_user_list,
      },
    ],
  },
  Employe: {
    main: "Employe",
    icon: Users,

    sublinks: [
      {
        name: "Add Employe",
        to: path.add_employe,
      },

      {
        name: "Employe List",
        to: path.employe_list,
      },
    ],
  },
  Settings: {
    main: "Settings",
    icon: GearSix,

    sublinks: [
      {
        name: "Global Setting",
        to: path.global_setting,
      },
      {
        name: "Popup Banner",
        to: '/web-settings/pop-up-banner',
      },
      {
        name: "Main Setting",
        to: path.main_setting,
      },
      {
        name: "Display Center",
        to: path.display_center,
      },
      {
        name: "Social Media",
        to: path.social_media,
      },
      {
        name: "Testimonials",
        to: path.testimonials,
      },
      {
        name: "Team Member",
        to: path.teammember,
      },
      {
        name: "Events List",
        to: path.events,
      },
      // {
      //   name: "Delivery Charge",
      //   to: path.delivery_charge_list,
      // },
    ],
  },
};

import React, { useEffect, useState } from "react";
import RequiredLabel from "../../CustomCommons/RequiredLabel";
import { Form, InputGroup } from "react-bootstrap";
import { handleInputs } from "../../../utils/HandleInputs";
import { BASE_URL } from "../../Const/Url";
import axios from "axios";
import Select from "react-select";
import eye from "../../../assets/Icon/eye.svg";
import swal from "sweetalert";
import { showToast } from "../../../utils/ToastHelper";
import * as path from "../../Routes/RoutePaths";
import { useHistory } from "react-router-dom";
import SelectOpenWithPagginationDynamic from "../../Const/SelectOpenWithPagginationDynamic";
import { handleSelectAll } from "../../Const/Status";

const MultipleArrival = () => {
  const history = useHistory();

  const [selectProduct, setSelectProduct] = useState([]);

  const [inputQuery, setInputQuery] = useState("");

  const [productList, setProductsList] = useState([]);

  

  const inputProduct = (inputValue) => {
    setInputQuery(inputValue);
  };


  const handleMultiInputChange = (newValue, actionMeta) => {
    setSelectProduct(newValue);
    
  };
  const postOffer = () => {
    const url = `${BASE_URL}api/v1/inventory/inventory/products/update_status/`;
    const data = {
      is_new_arrival: true,
      products: selectProduct ? selectProduct.map((obj) => obj.value) : [],
    };
    axios
      .post(url, data)
      .then((res) => {
        if (res.data.status) {
          showToast("success", "Arrival product added.");
          history.push(path.arrival_product_list);
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };

  console.log(productList,'pdl')
  console.log(selectProduct,'pdl2')
  return (
    <div className="categorey-parent new-categorey-parent an">
      <div className="bg-white  add-div">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>
            Add Multiple Arrival Product
          </h3>
        </div>
        <div
          style={{
            padding: "20px 35px ",
            borderBottom: " 0.5px solid #E0E0E0",
            borderRight: " 0.5px solid #E0E0E0",
            borderLeft: "0.5px solid #E0E0E0",
          }}
          className="row"
        >
          <div className="col-12 ">
            <RequiredLabel text="Products" />
            <div className="position-relative">
              {/* <Select
                // styles={styles}
                isMulti
                placeholder="---Select---"
                isClearable
                // defaultValue={selectedOption}
                onChange={handleMultiInputChange}
                onInputChange={inputProduct}
                options={productList}
              /> */}
              <SelectOpenWithPagginationDynamic
                handleMultiInputChange={handleMultiInputChange}
                inputProduct={inputProduct}
                ProductsList={productList}
                defaultValue={selectProduct}
                setProductsList={setProductsList}
                inputQuery={inputQuery}
                selectAll={()=> handleSelectAll(productList,setSelectProduct)}
                url1={`/api/v1/inventory/inventory/products/?is_show_website=True`}
                url2={`/api/v1/inventory/inventory/products/?query=${inputQuery}&is_new_arrival=False&is_show_website=True`}
              />
            </div>
          </div>

          {/* ------------Save And Cancel------------ */}
          <div className="mt-2 mt-md-4" style={{ marginLeft: "-10px" }}>
            <button
              onClick={postOffer}
              className=" save-btn  me-2 mt-2"
              style={{ background: "#000", color: "white" }}
            >
              Save
            </button>
            <button
              onClick={() => history.push(path.arrival_product_list)}
              className="save-btn mt-2"
              style={{ border: ".4px solid #E0E0E0 !important" }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultipleArrival;

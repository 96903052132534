import React, { useEffect, useRef, useState } from "react";
import RequiredLabel from "../../CustomCommons/RequiredLabel";
import { Form, InputGroup } from "react-bootstrap";
import { handleInputs } from "../../../utils/HandleInputs";
import { BASE_URL } from "../../Const/Url";
import axios from "axios";
import Select from "react-select";
import eye from "../../../assets/Icon/eye.svg";
import swal from "sweetalert";
import { showToast } from "../../../utils/ToastHelper";
import * as path from "../../Routes/RoutePaths";
import { useHistory } from "react-router-dom";
import SelectOpenWithPagginationDynamic from "../../Const/SelectOpenWithPagginationDynamic";
import { handleSelectAll } from "../../Const/Status";
const Checkbox = (props) => (
  <input style={{ transform: "scale(1.3)" }} type="checkbox" {...props} />
);

const AddMultipleFestivalProduct = () => {
  const history = useHistory();

  const ref = useRef(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [next, setNext] = useState(null);

  const [selectProduct, setSelectProduct] = useState([]);

  const [inputQuery, setInputQuery] = useState("");

  const [productList, setProductsList] = useState([]);

  const getProductList = () => {
    if (inputQuery?.length > 1) {
      let url;
      if (!inputQuery || inputQuery === "" || inputQuery === undefined) {
        url = `${BASE_URL}/api/v1/inventory/inventory/products/?limit=30`;
      } else if (inputQuery) {
        url = `${BASE_URL}/api/v1/inventory/inventory/products/?query=${inputQuery}&is_festival=False&limit=20`;
      }
      axios
        .get(url)
        .then((res) => {
          const result = res.data.data.results;
          console.log(result);

          const options = result.map((curr) => ({
            label: curr.name + " " + "[[" + curr?.sku + "]]",
            value: curr.id,
            slug: curr.slug,
          }));

          setProductsList(options);
          setNext(res.data.data.next); // Update next state

          setLoading(false); // Set loading state to false
        })
        .catch((err) => {
          // const message = JSON.parse(err.request.response).message;
          // console.log(err.request.response);
        });
    }
  };

  const getProductListNext = (
    inputQuery,
    setProductsList,
    setLoading,
    next,
    setNext
  ) => {
    setLoading(true); // Set loading state to true

    axios
      .get(next)
      .then((res) => {
        const { results } = res.data.data;

        const options = results.map((curr) => ({
          label: curr.name + " " + "[[" + curr?.sku + "]]",
          value: curr.id,
          price: curr.price,
          discount: curr.discount,
          stock: curr.stock,
          variant: curr.variants,
          thumb: `${curr.thumb_url}`,
        }));
        setProductsList((prevList) => [...prevList, ...options]); // Append new data to the existing list
        setNext(res.data.data.next); // Update next state

        setLoading(false); // Set loading state to false
      })
      .catch((err) => {
        console.error("Error fetching product list:", err);
        setLoading(false); // Set loading state to false even if there's an error
      });
  };

  const handleLoadMore = () => {
    getProductListNext(inputQuery, setProductsList, setLoading, next, setNext);
  };

  useEffect(() => {
    let timeoutId;
    const delayedFetch = () => {
      timeoutId = setTimeout(() => {
        // getProductList();
      }, 1000); //
    };
    delayedFetch();
    return () => {
      clearTimeout(timeoutId);
    };
  }, [inputQuery]);
  useEffect(() => {
    if (productList?.length > 0) {
      setMenuIsOpen(true);
    }
  }, [productList]);

  const toggleMenuIsOpen = () => {
    setMenuIsOpen((value) => !value);
    const selectEl = ref.current;
    if (!selectEl) return;
    if (menuIsOpen) selectEl.blur();
    else selectEl.focus();
  };

  const inputProduct = (inputValue) => {
    setInputQuery(inputValue);
  };

  // useEffect(() => {
  //   getProductList();
  // }, [inputQuery]);

  const handleMultiInputChange = (newValue, actionMeta) => {
    const newVal = actionMeta?.option?.label?.split("[[")[0];
    console.log(newValue, "nv");
    setSelectProduct(newValue);
  };

  const postOffer = () => {
    const url = `${BASE_URL}api/v1/inventory/inventory/products/update_status/`;
    const data = {
      is_festival: true,
      products: selectProduct ? selectProduct.map((obj) => obj.value) : [],
    };
    axios
      .post(url, data)
      .then((res) => {
        if (res.data.status) {
          showToast("success", "Festival product added.");
          history.push(path.festival_product_list);
        }
      })
      .catch((err) => {
        const message = JSON.parse(err.request.response).message;
        const errorMsg = JSON.parse(err.request.response).errors;
        for (let key in errorMsg) {
          showToast("error", `${key} : ${errorMsg[key][0]}`);
        }
        showToast("error", message);
      });
  };
  return (
    <div className="categorey-parent new-categorey-parent an">
      <div className="bg-white  add-div">
        <div
          className="d-flex align-items-center"
          style={{ padding: "20px 35px ", border: "0.5px solid #E0E0E0" }}
        >
          <h3 style={{ fontSize: "23px", marginTop: "6px" }}>
            Add Multiple Festival Product
          </h3>
        </div>
        <div
          style={{
            padding: "20px 35px ",
            borderBottom: " 0.5px solid #E0E0E0",
            borderRight: " 0.5px solid #E0E0E0",
            borderLeft: "0.5px solid #E0E0E0",
          }}
          className="row"
        >
          <div className="col-12 ">
            <RequiredLabel text="Products" />
            {/* <div className="my-2 d-flex justify-content-between align-items-center gap-3">
              <div>
                <Checkbox
                  checked={menuIsOpen}
                  onChange={toggleMenuIsOpen}
                  id="cypress-single__clearable-checkbox"
                />
                <span className="ms-2">{menuIsOpen ? "Close" : "Open"}</span>
              </div>
              <div>
                {next !== null && (
                  <button
                    className="btn btn-danger"
                    onClick={handleLoadMore}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Load More"}
                  </button>
                )}
              </div>
            </div>
            <div className="position-relative">
              <Select
                // styles={styles}
                ref={ref}
                menuIsOpen={menuIsOpen}
                isMulti
                placeholder="---Select---"
                isClearable
                defaultValue={selectProduct}
                onChange={handleMultiInputChange}
                onInputChange={inputProduct}
                options={productList}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: state.isFocused ? "blue" : "black",
                    boxShadow: state.isFocused
                      ? "0 0 0px 0.5px rgba(255, 0, 0, 1)"
                      : "none",
                    "&:hover": {
                      borderColor: "blue",
                    },
                  }),
                }}
              />
            </div> */}
            <SelectOpenWithPagginationDynamic
              handleMultiInputChange={handleMultiInputChange}
              inputProduct={inputProduct}
              ProductsList={productList}
              defaultValue={selectProduct}
              setProductsList={setProductsList}
              inputQuery={inputQuery}
              selectAll={()=> handleSelectAll(productList,setSelectProduct)}
              url1={`/api/v1/inventory/inventory/products/?is_show_website=True`}
              url2={`/api/v1/inventory/inventory/products/?query=${inputQuery}&is_festival=False&is_show_website=True`}
            />
          </div>

          {/* ------------Save And Cancel------------ */}
          <div className="mt-2 mt-md-4" style={{ marginLeft: "-10px" }}>
            <button
              onClick={postOffer}
              className=" save-btn  me-2 mt-2"
              style={{ background: "#000", color: "white" }}
            >
              Save
            </button>
            <button
              onClick={() => history.push(path.festival_product_list)}
              className="save-btn mt-2"
              style={{ border: ".4px solid #E0E0E0 !important" }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMultipleFestivalProduct;

export const dasshboard = "/";
// inventory paths
export const add_category = "/inventory/add-category";
export const category_list = "/inventory/category-list";
export const add_product = "/inventory/add-product/0" || "/inventory/add-product/1";
// export const add_product = ;
export const product_list = "/inventory/product-list";
export const home_decor_list = "/inventory/home-decor-list";
export const product_view = "/inventory/product-view";
export const add_attribute = "/inventory/add-attribute";
export const bulk_import = "/inventory/bulk-import";
// trending
export const trending_product_list = "/inventory/trending-product-list";
export const add_trending_product = "/inventory/add-trending-product";
export const add_multiple_trending_product = "/inventory/add-multiple-trending-product";
// new arrival
export const arrival_product_list = "/inventory/arrival-product-list";
export const add_arrival_product = "/inventory/add-arrival-product";
export const add_multiple_arrival_product = "/inventory/add-multiple-arrival-product";
// is_highlighted
export const highlighted_product_list = "/inventory/highlighted-product-list";
export const add_highlighted_product = "/inventory/add-highlighted-product";
export const add_multiple_highlighted_product = "/inventory/add-multiple-highlighted-product";
// Sales paths
export const create_invoice = "/sales/create-invoice";
export const create_invoice_l = "/sales/create-invoice-l";
export const invoice_list = "/sales/invoice-list";
export const view_invoice = "/sales/view-invoice/";
// draft
export const create_draft_invoice = "/draft/create-draft-invoice";
export const draft_invoice_list = "/draft/draft-invoice-list";
// customars path
export const customer_list = "/customer/customer-list";
export const add_customer = "/customer/add-customer";
export const customer_bulk_import = "/customer/bulk-import";
// notification
export const notification = "/notification";
// user path
export const add_user = "/user/add-user";
export const add_role = "/user/add-role";
export const add_group = "/user/add-group";
export const user_list = "/user/user-list";
export const web_user_list = "/user/website_user";
export const user_view = "/user/user-View";
// employe
export const add_employe = "/employee/add-employee";
export const employe_list = "/employee/employee-list";
// reports
export const reports = "/report/reports";
export const sales_report = "/report/daily-sales-report";
export const sales_report_optimise = "/report/daily-sales-report";
export const area_report = "/report/area-invoice-report";
export const discount_report = "/report/discount-report";
export const sales_report_dash = "/report/product-report";
export const sales_report_inv = "/report/sales-report-invoice";
// custom
export const custom_create_invoice = "/custom/create-custom-invoice";
export const custom_invoice_list = "/custom/custom-invoice-list";
export const custom_sales_report = "/custom/custom-sales-report";
export const purchace_order_list = "/custom/purchace-order-list";
export const add_purchace_order = "/custom/add-purchace-order";
export const add_requisition_order = "/custom/add-requisition-order";
export const requisition_order_list = "/custom/requisition-order-list";
export const requisition_order_report = "/custom/requisition-order-report";
export const purchace_order_report = "/custom/purchace-order-report";
// offers
export const offer = "/promotions/offer";
export const combooffer = "/promotions/combo-offer";
export const comboofferAdd = "/promotions/add-combo-offer";
export const cupons = "/promotions/coupons";
export const review = "/promotions/review";
export const banner = "/promotions/banner";
export const contactUs = "/promotions/contact-us";
export const work_with_us = "/promotions/work_with_us";
// web settings
export const global_setting = "/web-settings/global-setting";
export const main_setting = "/web-settings/main-setting";
export const display_center = "/web-settings/display-center";
export const testimonials = "/web-settings/testimonials";
export const teammember = "/web-settings/teammember";
export const delivery_charge_list = "/web-settings/delivery_charge_list";
export const add_delivery_charge = "/web-settings/add_delivery_charge";
export const events = "/web-settings/events";
export const add_event = "/web-settings/add_event";
export const edit_event = "/web-settings/edit_event/:slug";
// social media 
export const social_media = '/web-settings/social-media'
export const add_social_media = '/web-settings/add-social-media'
export const edit_social_media = '/web-settings/edit-social-media'
// outlets
export const add_outlet = '/outlet/add_outlet'
export const outlet_list = '/outlet/outlet_list'
export const outlet_user_list = '/outlet/outlet_user_list'
export const add_outlet_user = '/outlet/add_outlet_user'
export const add_outlet_products = '/outlet/add_outlet_products'
export const outlet_products_list = '/outlet/outlet_products_list'
export const return_products_list = '/outlet/return_products_list'
export const return_products_view = '/outlet/return_products_view'
// Festivals
export const add_festival = '/festival/add_festival'
export const festival_list = '/festival/festival_list'
export const festival_product_list = '/product/festival_product_list'
export const add_festival_product = '/product/add_festival_multiple_product'
//  matching
export const add_matching = '/product/add_matching'
export const matching_list = '/product/matching_list'